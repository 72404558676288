import React from 'react';
import Layout from '../components/Layout';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../components/Colors';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import ArmoryMap from '../constants/ArmoryMap';

const lastUpdate = 'July 19, 2021';

const renderSectionCards = (key, index) => {
  const currentKey = 'rsc-' + index;
  const backgroundColor = key.backgroundColor
    ? key.backgroundColor
    : Colors.main;
  return (
    <li
      className="armory-card-wrapper"
      key={currentKey}
      css={css`
        display: -webkit-box;
        display: flex;
        padding: 1rem;
      `}
    >
      <a
        css={css`
          text-decoration: none !important;
          &hover: {
            text-decoration: none !important;
          }
        `}
        href={'https://www.christianbytes.com/armory/' + key.link}
      >
        <div
          css={css`
            width: 300px;
            height: 540px;

            background-color: white;
            border-radius: 1.25rem;
            box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
            display: -webkit-box;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            flex-direction: column;
            overflow: hidden;
          `}
        >
          <div
            css={css`
              position: relative;
              height: 305px;
              margin-bottom: 35px;
              border-top-left-radius: 14px;
              border-top-right-radius: 14px;
              background-image: url(${key.picture});
              background-repeat: no-repeat;
              background-position: center;
            `}
          ></div>
          <div
            css={css`
              height: 314px;
            `}
          >
            <div
              css={css`
                text-transform: uppercase;
                text-align: center;
                font-size: 12px;
                font-weight: 700;
                margin-bottom: 3px;
                color: ${backgroundColor};
              `}
            >
              {key.armoryCallout}
            </div>
            <div
              css={css`
                font-size: 26px;
                font-weight: 900;
                margin-bottom: 5px;
                text-align: center;
                color: #5d6769;
              `}
            >
              {key.title}
            </div>
            <div
              css={css`
                padding: 10px;
                margin-bottom: 10px;
                color: #58574f;
                text-align: center;
              `}
            >
              {key.previewText ? key.previewText : key.intro}
            </div>
          </div>{' '}
          <div
            css={css`
              color: white;
              font-weight: 700;
              border-bottom-left-radius: 14px;
              border-bottom-right-radius: 14px;
              background: ${backgroundColor};
            `}
          >
            <div
              css={css`
                height: 60px;
                line-height: 60px;
                text-align: center;
              `}
            >
              <div
                css={css`
                  margin: auto;
                  font-variant: small-caps;
                  font-size: 1.25rem;
                  vertical-align: middle;
                  color: ${Colors.light};
                  &:hover {
                    text-decoration: underline;
                    color: ${Colors.light};
                  }
                `}
                className="link"
              >
                view the resources >
              </div>
            </div>
          </div>
        </div>
      </a>
    </li>
  );
};

// Each page will use <> wrapper to get global styles.
export default () => {
  return (
    <>
      <Layout
        gridMainExtraClasses="armory-override"
        jumbo={true}
        pageTitle="Christian Armory: Arm Yourself with God's Word"
        pageDescription="Arm yourself in the digital world with God's Word! This page will help you easily find great Chrisitan apps & sites for Bible study, Christian radio, podcasts, tv, movies and more!"
      >
        <h1
          css={css`
            color: ${Colors.main} !important;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 2rem;
            border-bottom: 2px solid ${Colors.main};
          `}
        >
          <FontAwesomeIcon
            css={css`
              color: ${Colors.main} !important;
              margin-right: 0.5rem;
            `}
            icon={faShieldAlt}
            size={'sm'}
            width="28"
          />
          CHRISTIAN ARMORY
        </h1>
        <p
          css={css`
            text-align: center;
            font-size: 0.8rem;
            color: ${Colors.secondary};
          `}
        >
          Last Update: {lastUpdate}
        </p>
        <p
          css={css`
            text-align: center;
            font-size: 1.15rem;
            font-weight: bold;
            color: ${Colors.main};
          `}
        >
          Want to live a Christ-centered life?
        </p>
        <p
          css={css`
            text-align: center;
            font-size: 1.15rem;
            margin: 0 auto 1rem auto;
          `}
        >
          Arm yourself with these Christian tools! <br />
          <br />
          The Armory is where you'll find good Christian tools that I use or
          have used.
        </p>
        <p
          css={css`
            text-align: center;
            font-size: 1.15rem;
            margin: 0 auto 1rem auto;
          `}
        ></p>

        <p
          css={css`
            text-align: center;
            font-size: 1.15rem;
            margin: 0 auto 1rem auto;
          `}
        >
          Select a section of the Armory below to get recommendations!
        </p>
        <ul
          css={css`
            display: -webkit-box;
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            justify-content: center;
          `}
        >
          {Object.keys(ArmoryMap).map(function(key, index) {
            return renderSectionCards(ArmoryMap[key], index);
          })}
        </ul>
      </Layout>
    </>
  );
};
